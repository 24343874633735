import React from 'react'
import html from '../../images/icons8-html-5-96.png'
import js from '../../images/icons8-javascript-96.png'
import dart from '../../images/icons8-dart-96.png'
import python from '../../images/icons8-python-96.png'
import axios from 'axios';

export default function Card({Title, Repo, Publish, Lang, Live}) {

    let date = new Date(Publish);

    const setImage = (lang) => {
        switch (lang) {
            case "JavaScript":
                return js
            case "HTML":
                return html    
            case "Dart":
                return dart
            case "Python":
                return python     
            default:
                break;
        }
    }

    return (
        <div>
        <div class="card" style={{marginBottom: "1rem", marginTop: "0.6rem"}}>
        <div class="card-body">
        <p style={{fontSize: "1rem", marginTop: "-0.8rem", fontWeight: "bold"}}>{Title}</p>
        <p style={{fontSize: "0.8rem", marginTop: "-0.8rem", fontWeight: "lighter", marginBottom: "-0.5rem"}}>Created At {date.toString()}</p>
        </div>
        <div class="card-footer">
            <ul class="nav nav-pills card-header-pills" style={{float: "right"}}>
            <li class="nav-item" style={{marginLeft: "0.5rem", marginRight:"0.5rem"}}>
                <a class="btn btn-success" target="_blank" href={Repo} style={{fontSize: "0.8rem"}}>View Repository</a>
            </li>
            <li class="nav-item" style={{marginLeft: "0.5rem", marginRight:"0.5rem"}}>
                <a class="btn btn-success" target="_blank" href={Live} style={{fontSize: "0.8rem"}}>Live</a>
            </li>
            </ul>
            <ul class="nav nav-pills">
            <li class="nav-item">
                <img src={setImage(Lang)} alt="" width="30rem"/>
            </li>
            </ul>
        </div>
        </div>
        </div>
    )
}
