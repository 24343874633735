import React from "react";
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './components/Home'

import "bootstrap/dist/css/bootstrap.css";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" exact element={<Home/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
