import React from 'react';
import idea from '../../images/undraw_ideas_s70l.svg';
import '../../App.css';
import cv from '../../docs/pasindu_senarathne.pdf';
import { ImWhatsapp } from 'react-icons/im';
import { HiOutlineMail } from 'react-icons/hi';

export default function HireMe() {
	return (
		<div
			className='container'
			style={{ background: '#5cb85c', color: 'white', marginBottom: '2rem' }}>
			<div class='container'>
				<div class='row' style={{ padding: '4rem' }}>
					<div class='col-sm' align='left'>
						<p style={{ fontSize: '1.6rem' }}>Ok Then!</p>
						<p style={{ fontSize: '3rem', marginTop: '-1.5rem' }}>
							<b>Have a Project on Your Mind?</b>
						</p>
						<p style={{ fontSize: '1rem', marginTop: '-1rem' }}>
							Tell Your Idea. We can make it real using the latest technologies.
							I guarantee to be the best collaborator to achieve far more than
							your expectations. I warmly welcome the developer community to
							connect with me.
						</p>
						<div align="right">
                        <a
							href='mailto:hello@pasindu.pro'
							target='_blank'
							className='btn btn-outline-light'
							style={{
								fontSize: '1.5rem',
								borderRadius: '6rem',
								paddingLeft: '3rem',
								paddingRight: '3rem',
							}}>
							Connect With Me
						</a>
                        </div>
					</div>
					{/* <div class="col-sm" className="img" align="right" style={{marginTop: "1.5rem"}}>
            <img src={idea} alt="" width="320rem" class="img-fluid"/>
            </div> */}
				</div>
			</div>
		</div>
	);
}
