import React,{useEffect, useState} from 'react'
import axios from 'axios';
import dev from '../../images/undraw_Designer_by46.svg'
import Cards from './Card'
import { ImGithub } from 'react-icons/im';
import { ImNpm } from 'react-icons/im';

export default function Projects() {

    const [github_repo, setgithub_repo] = useState([]);

    useEffect(()=>{
        axios.get("https://api.github.com/search/repositories?q=user:PasinduS96+sort:updated").then(result =>{
            console.log(result.data.items[0].html_url);
            setgithub_repo(result.data.items)
        })
    },[])

    return (
        <div className="container">
        <div align="center">
        <div class="container" style={{marginTop: "2rem", marginBottom: "2rem"}} >
        <div style={{marginTop: "2rem", marginBottom: "2rem"}} align="center">
            <p style={{fontSize: "1rem", color: "#5cb85c"}}>Recent Projects</p>
            <p style={{fontSize: "3rem", marginTop: "-1.2rem"}}><b>GitHub Feed</b></p>
        </div>
        </div>
        <div class="container">
        <div class="row">
            <div class="col-sm">
            <img src={dev} alt="" class="img-fluid" width="500rem"/>
            <div className="container-fluid">
            <a href="https://github.com/PasinduS96?tab=repositories" target="_blank" className="btn btn-outline-dark" style={{marginTop: "1.5rem",marginLeft: "1rem", marginRight: "1rem"}}><ImGithub/> View More Projects</a>
            <a href="https://www.npmjs.com/~pasindusenarathne" target="_blank" className="btn btn-outline-danger" style={{marginTop: "1.5rem", marginLeft: "1rem", marginRight: "1rem"}}><ImNpm/> Open Source Libraries</a>
            </div>
            </div>
            <div class="col-sm" align="left">
            {github_repo.map((items, i) =>{
            if(i <3){return <Cards Title={items.name} Repo={items.html_url} Publish={items.created_at} Lang={items.language} Live={items.description}/>}
            })}
        </div>
        </div>
        </div>
        <hr />
        </div>
        </div>
    )
}
