import React from 'react';
import { FiBookOpen } from 'react-icons/fi';
import { FaLaptopCode } from 'react-icons/fa';
import Graduation from '../../images/icons8-student-male-96.png';
import al from '../../images/icons8-diploma-96 (1).png';
import office from '../../images/icons8-office-96 (1).png';
import free from '../../images/icons8-person-96.png';

export default function Education() {
	return (
		<div className='container'>
			<div align='center'>
				<div
					class='container'
					style={{ marginTop: '2rem', marginBottom: '2rem' }}>
					<div
						style={{ marginTop: '2rem', marginBottom: '2rem' }}
						align='center'>
						<p style={{ fontSize: '1rem', color: '#5cb85c' }}>
							My Qualifications
						</p>
						<p style={{ fontSize: '3rem', marginTop: '-1.2rem' }}>
							<b>Awesome Journey</b>
						</p>
					</div>
					<div class='row'>
						<div class='col-sm'>
							<p style={{ fontSize: '1.2rem' }}>
								<FiBookOpen />
								<b> Education</b>
							</p>
							<div
								class='w-75'
								style={{ marginTop: '2rem', marginBottom: '2rem' }}>
								<div>
									<img
										src={Graduation}
										alt=''
										width='90rem'
										className='img-fluid'
										style={{ marginBottom: '1rem' }}
									/>
									<p
										style={{ fontSize: '1.2rem', fontWeight: 'bold' }}
										class='card-title'>
										Graduated From Sri Lanka Intitute Of Information Technology
									</p>
									<p
										style={{ fontSize: '1rem', fontWeight: 'normal' }}
										class='card-text'>
										2021
									</p>
									<p
										style={{
											fontSize: '0.8rem',
											fontWeight: 'normal',
											marginTop: '-0.8rem',
										}}
										class='card-text'>
										Bs.C (Hons) Information Technology - Sepecialized In
										Software Engineering
									</p>
									<p
										style={{
											fontSize: '0.7rem',
											fontWeight: 'lighter',
											marginTop: '-0.8rem',
										}}
										class='card-text'>
										Second Class Uper Divison (GPA 3.34/4.00)
									</p>
								</div>
							</div>
							<div
								class='w-75'
								style={{ marginTop: '2rem', marginBottom: '2rem' }}>
								<div>
									<img
										src={al}
										alt=''
										width='90rem'
										className='img-fluid'
										style={{ marginBottom: '1rem' }}
									/>
									<p
										style={{ fontSize: '1.2rem', fontWeight: 'bold' }}
										class='card-title'>
										G.C.E Advanced Level Examination
									</p>
									<p
										style={{ fontSize: '1rem', fontWeight: 'normal' }}
										class='card-text'>
										2015
									</p>
									<p
										style={{
											fontSize: '0.8rem',
											fontWeight: 'normal',
											marginTop: '-0.8rem',
										}}
										class='card-text'>
										Physical Science Stream
									</p>
									<p
										style={{
											fontSize: '0.7rem',
											fontWeight: 'lighter',
											marginTop: '-0.8rem',
										}}
										class='card-text'>
										President's College - Kotte
									</p>
								</div>
							</div>
						</div>
						<div class='col-sm'>
							<p style={{ fontSize: '1.2rem' }}>
								<FaLaptopCode />
								<b> Work Experience</b>
							</p>
							<div
								class='w-75'
								style={{ marginTop: '2rem', marginBottom: '2rem' }}>
								<div>
									<img
										src={office}
										alt=''
										width='90rem'
										className='img-fluid'
										style={{ marginBottom: '1rem' }}
									/>
									<div>
									<p
										style={{ fontSize: '1.2rem', fontWeight: 'bold' }}
										class='card-title'>
										Software Engineer
									</p>
									<p
										style={{ fontSize: '1rem', fontWeight: 'normal' }}
										class='card-text'>
										2021 August - Present{' '}
									</p>
									<p
										style={{
											fontSize: '0.8rem',
											fontWeight: 'normal',
											marginTop: '-0.8rem',
										}}
										class='card-text'>
										Ceylon Solutions (Pvt) Ltd
									</p>
									<p
										style={{
											fontSize: '0.7rem',
											fontWeight: 'lighter',
											marginTop: '-0.8rem',
										}}
										class='card-text'>
										Colombo, Sri Lanka
									</p>
									</div>
								</div>
							</div>
							<div
								class='w-75'
								style={{ marginTop: '2rem', marginBottom: '2rem' }}>
								<div>
									<img
										src={free}
										alt=''
										width='90rem'
										className='img-fluid'
										style={{ marginBottom: '1rem' }}
									/>
									<p
										style={{ fontSize: '1.2rem', fontWeight: 'bold' }}
										class='card-title'>
										Freelance Software Developer
									</p>
									<p
										style={{ fontSize: '1rem', fontWeight: 'normal' }}
										class='card-text'>
										2020 August - 2021 August
									</p>
									<p
										style={{
											fontSize: '0.8rem',
											fontWeight: 'normal',
											marginTop: '-0.8rem',
										}}
										class='card-text'>
										Self Employed
									</p>
									<p
										style={{
											fontSize: '0.7rem',
											fontWeight: 'lighter',
											marginTop: '-0.8rem',
										}}
										class='card-text'>
										Colombo, Sri Lanka
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<hr />
			</div>
		</div>
	);
}
