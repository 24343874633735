import React from 'react'
import offer from '../../images/undraw_feeling_proud_qne1.svg'
import { MdWeb } from 'react-icons/md';
import { MdSettingsSystemDaydream } from 'react-icons/md';
import { BiMobileAlt } from 'react-icons/bi';
import { FaRegHandshake } from 'react-icons/fa';

export default function Offer() {
    return (
        <div className="container">
        <div align="center">
        <div class="container" style={{marginTop: "2rem", marginBottom: "2rem"}} >
        <div style={{marginTop: "2rem", marginBottom: "2rem"}} align="center">
            <p style={{fontSize: "1rem", color: "#5cb85c"}}>What I Can Do</p>
            <p style={{fontSize: "3rem", marginTop: "-1.2rem"}}><b>Specialized Areas</b></p>
        </div>
        <div class="container">
        <div class="row">
            <div class="col-sm">
            <img src={offer} alt="" class="img-fluid" width="400rem"/>
            </div>
            <div class="col-sm" align="left">
            <div class="list-group ">
            <div class="list-group-item list-group-item-action flex-column align-items-start" style={{border:"0 none"}}>
            <div class="">
            <h5 class="mb-1"><MdWeb style={{marginRight: "0.5rem"}}/>Web Application Development</h5>
            </div>
            <p class="mb-1" style={{fontSize: "0.8rem", fontWeight: "initial"}}>I am making responsive and modern wesites to digitalize the business and various kind of web applications which beifits to our lifestyle</p>
            </div>

            <div class="list-group-item list-group-item-action flex-column align-items-start" style={{border:"0 none"}}>
            <div class="">
            <h5 class="mb-1"><MdSettingsSystemDaydream style={{marginRight: "0.5rem"}}/>Web Based Systems</h5>
            </div>
            <p class="mb-1" style={{fontSize: "0.8rem", fontWeight: "initial"}}>Web based systems allow people to control and monitor operations of their business from anywhere in the world.</p>
            </div>

            <div class="list-group-item list-group-item-action flex-column align-items-start" style={{border:"0 none"}}>
            <div class="">
            <h5 class="mb-1"><BiMobileAlt style={{marginRight: "0.5rem"}}/>Mobile Application Development</h5>
            </div>
            <p class="mb-1" style={{fontSize: "0.8rem", fontWeight: "initial"}}>Mobile applications can boost up the business capabilities and customer involovement of modern business.</p>
            </div>

            <div class="list-group-item list-group-item-action flex-column align-items-start" style={{border:"0 none"}}>
            <div class="">
            <h5 class="mb-1"><FaRegHandshake style={{marginRight: "0.5rem"}}/>Collaborating Projects</h5>
            </div>
            <p class="mb-1" style={{fontSize: "0.8rem", fontWeight: "initial"}}>I am open for collaborate on your open source projects</p>
            </div>

            </div>
            </div>
        </div>
        </div>
        </div>
        </div>
        <hr/>
        </div>
    )
}
