import React from 'react';
import Header from './Header/HeaderImage';
import AboutMe from './AboutMe/AboutMe';
import Expert from './Expertise/Expert';
import Education from './Education/Education';
import Offer from './Offer/Offer';
import Github from './GitHub/Github';
import Hire from './Hire/HireMe';

const Home = () => {
	return (
		<div className='container'>
			<Header />
			<AboutMe />
			<Expert />
			<Education />
			<Offer />
			<Github />
			<Hire />
		</div>
	);
};

export default Home;
