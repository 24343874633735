import React from 'react';
import about from '../../images/undraw_about_me_wa29.png';
import { MdEmail } from 'react-icons/md';
import { ListItem, UnorderedList } from '@chakra-ui/react';

export default function AboutMe() {
	return (
		<div className='container' align='center'>
			<div
				class='container'
				style={{ marginTop: '2rem', marginBottom: '2rem' }}>
				<div class='row'>
					<div class='col-sm'>
						<div
							style={{ marginTop: '2rem', marginBottom: '2rem' }}
							align='left'>
							<p style={{ fontSize: '1rem', color: '#5cb85c' }}>
								Who is this Developer
							</p>
							<p style={{ fontSize: '3rem', marginTop: '-1.2rem' }}>
								<b>About Me</b>
							</p>
							<p style={{ fontSize: '1rem', marginTop: '-0.5rem' }}>
								I am a Full-stack Engineer who has experience in software
								development with a demonstrated history of working in the
								computer software industry, skilled in Web Development, Mobile
								app development, Version Controlling, and Project Management.
							</p>
							<p style={{ fontSize: '1rem', marginTop: '-0.5rem' }}>As a programmer,</p>
							<UnorderedList spacing={5}>
								<ListItem>
									I am Highly organized with exceptional commitment to task
									completion and quality assurance when working with computer
									software programs.
								</ListItem>
								<ListItem>
									I Committed to delivering customer's objectives to projects and
									results to customers and improving each version of software I
									engage with.
								</ListItem>
								<ListItem>
									I have Ability to solve complex problems successfully with minimal
									guidance or help using a logical reasoning technique and a
									strong attention to detail.
								</ListItem>
							</UnorderedList>
							<br />
							<p style={{ fontSize: '1rem', marginTop: '-0.5rem' }}>
								Feel free to contact me, Let's share our knowledge!
							</p>
							<div>
								<a
									href='mailto:hello@pasindu.pro'
									style={{
										textDecoration: 'none',
										fontSize: '1rem',
										marginTop: '-0.5rem',
										color: '#5cb85c',
									}}>
									<MdEmail /> hello@pasindu.pro
								</a>
							</div>
						</div>
					</div>
					<div class='col-sm'>
						<img src={about} alt='' class='img-fluid' width='600rem' />
					</div>
				</div>
			</div>
			<hr />
		</div>
	);
}
