import React from 'react';
import profileimage from '../../images/profile.png';
import facebook from '../../images/icons8-facebook-96.png';
import linked from '../../images/icons8-linkedin-96.png';
import github from '../../images/icons8-github-100.png';
import email from '../../images/icons8-gmail-login-96.png';
import { Image } from '@chakra-ui/react';

export default function HeaderContent() {
	return (
		<div class='container text-center'>
			<div align='center'>
				{/* <ReactRoundedImage
          image={profileimage}
          roundedColor="#5cb85c"
          imageWidth="250"
          imageHeight="250"
          roundedSize="5"
        /> */}
				<img
					src={profileimage}
					alt='user'
					width='350px'
					style={{
						borderRadius: '50%',
						borderColor: '#5cb85c',
						borderWidth: '8px',
						borderStyle: 'solid',
                        marginLeft: '-20px',
                        marginRight: '-20px',
					}}
				/>
			</div>
			<div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
				<p style={{ fontSize: '2rem', color: '#5cb85c' }}>Hi, I Am</p>
				<p
					style={{ fontSize: '3.5rem', marginTop: '-1.2rem' }}
					className='text-center'>
					<b>Pasindu Senarathne</b>
				</p>
				<p style={{ fontSize: '1.5rem', marginTop: '-1.2rem' }}>
					<b>Full-Stack Software Developer</b>
				</p>
				<p style={{ fontSize: '1rem', marginTop: '-0.5rem' }}>
					I am fullstack developer who shot what able to cold new that see hold.
					Let's work together
				</p>
				<div className='container-fluid' align='center'>
					<div className='container-fluid' style={{ position: 'sticky' }}>
						<a
							href='https://www.facebook.com/pasindubhagya.senarathne/'
							target='_blank'>
							<img src={facebook} alt='' class='img-fluid' width='30rem' />
						</a>

						<a
							href='https://github.com/PasinduS96'
							target='_blank'
							style={{ marginLeft: '1rem' }}>
							<img src={github} alt='' class='img-fluid' width='33rem' />
						</a>

						<a
							href='https://www.linkedin.com/in/pasindu-senarathne-7b96a313a/'
							target='_blank'
							style={{ marginLeft: '1rem' }}>
							<img src={linked} alt='' class='img-fluid' width='30rem' />
						</a>

						<a
							href='mailto:pasindubahagya@gmail.com'
							target='_blank'
							style={{ marginLeft: '1rem' }}>
							<img src={email} alt='' class='img-fluid' width='30rem' />
						</a>
					</div>
				</div>
			</div>
			<hr />
		</div>
	);
}
