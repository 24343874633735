import React from 'react'
import react from '../../images/iconfinder_React.js_logo_1174949.png'
import node from '../../images/node.png'
import java from '../../images/java-logo-1.png'
import python from '../../images/267_Python_logo-512.webp'

export default function Expert() {
    return (
        <div align="center">
        <div class="container" style={{marginTop: "2rem", marginBottom: "2rem"}} >
        <div style={{marginTop: "2rem", marginBottom: "2rem"}} align="center">
                <p style={{fontSize: "1rem", color: "#5cb85c"}}>What I Know</p>
                <p style={{fontSize: "3rem", marginTop: "-1.2rem"}}><b>My Expertise Area</b></p>
        </div>
        <div class="row">
            <div class="col">
            <img src={react} alt="" className="img-fluid" width="210rem"/>
            </div>
            <div class="col" node>
            <img src={node} alt="" className="img-fluid" width="210rem"/>
            </div>
            <div class="col">
            <img src={java} alt="" className="img-fluid" width="210rem"/>
            </div>
            <div class="col">
            <img src={python} alt="" className="img-fluid" width="200rem"/>
            </div>
        </div>
        </div>
        <hr/>
        </div>
    )
}
