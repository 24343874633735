import React from 'react';
import headerImage from '../../images/6880436_preview.png'
import HeaderContent from './HeaderContent'


export default function Header() {
    return (
        <div>
            <div style={{marginTop: "4rem", marginLeft: "auto", marginRight: "auto"}}>
                <HeaderContent />
            </div>
        </div>
    )
}
